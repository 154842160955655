import { useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import parse from 'html-react-parser';
import { QuestionType } from 'types/exam';
import AlternativeCard from 'components/molecules/AlternativeCard';
import NextButton from 'components/atoms/NextButton';
import { useStyles } from './styles';

interface QuestionProps {
  question: QuestionType;
  situation: {
    currentPosition: number;
    totalQuestions: number;
  };
  selectedAnswerId: string;
  markAnswer: (id: string) => void;
  next: (time: number) => void;
  isLoading: boolean;
}

function Question({
  question,
  situation,
  selectedAnswerId,
  markAnswer,
  next,
  isLoading
}: QuestionProps) {
  const styles = useStyles();

  const { description, choices, time, id: idQuestion } = question;
  const { currentPosition, totalQuestions } = situation;
  const [secondsAmount, setSecondsAmount] = useState(60);

  const ref = useRef<any>(null);
  const [width, setWidth] = useState(0);

  let interval: NodeJS.Timer | number = 0;

  useEffect(() => {
    clearInterval(interval);
    setSecondsAmount(time);
  }, [idQuestion]);

  useEffect(() => {
    if (secondsAmount > 0) {
      interval = setInterval(() => {
        setSecondsAmount((state) => state - 1);
      }, 1000);
    } else {
      next(secondsAmount);
    }
    return () => clearInterval(interval);
  }, [secondsAmount]);

  useEffect(() => {
    setWidth(ref.current.offsetWidth);

    const getwidth = () => {
      setWidth(ref.current.offsetWidth);
    };

    window.addEventListener('resize', getwidth);

    return () => window.removeEventListener('resize', getwidth);
  }, []);

  const minutes = Math.floor(secondsAmount / 60);
  const seconds = secondsAmount % 60;
  const formattedTime = `${minutes}:${String(seconds).padStart(2, '0')}`;

  const isLastQuestion = currentPosition === totalQuestions;

  return (
    <>
      <Typography className={styles.currentPosition}>
        {currentPosition} DE {totalQuestions}
      </Typography>
      <Typography variant="h5" className={styles.description}>
        {parse(description)}
      </Typography>
      <Box className={styles.list} ref={ref}>
        {choices.map(({ answer, id }) => (
          <AlternativeCard
            key={id}
            title={answer}
            selected={id === selectedAnswerId}
            onClick={() => markAnswer(id)}
            disabled={isLoading}
          />
        ))}
      </Box>
      <Box style={{ height: 50 }} />
      <Box className={styles.nextContainer} style={{ width }}>
        <Box className={styles.timeContainer}>
          <Typography variant="caption" className={styles.time}>
            {formattedTime}
          </Typography>
        </Box>
        <NextButton
          onClick={() => next(secondsAmount)}
          disabled={!selectedAnswerId}
          text={isLastQuestion ? 'concluir' : ''}
          loading={isLoading}
        />
      </Box>
    </>
  );
}

export default Question;
