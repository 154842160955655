import { AxiosError, AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';

import { ExamService } from 'services/exam.service';
import { CandidateExamResultType, ExamInfoType, ExamType } from 'types/exam';
import { setExamInfoAction, setExamAction, setExamResult } from './actions';
import {
  ExamSagaActionsType,
  GetExamActionPayloadType,
  SendExamAnswersActionPayloadType
} from './types';

import { setIsLoadingAction, setMessageAction } from '../helper/actions';

function* getExamInfoSaga(action: GetExamActionPayloadType) {
  const { examId, callback } = action.payload;

  yield put(setIsLoadingAction(true));
  try {
    const response: AxiosResponse<ExamInfoType> = yield call(
      ExamService.getExamInfo,
      examId
    );
    const { data } = response;

    yield put(setExamInfoAction(data));

    yield put(setMessageAction(null));

    if (callback) yield call(callback);
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(setMessageAction({ type: 'error', content: error.message }));
    }
  }
  yield put(setIsLoadingAction(false));
}

function* getExamSaga(action: GetExamActionPayloadType) {
  const { examId, callback } = action.payload;

  yield put(setIsLoadingAction(true));
  try {
    const response: AxiosResponse<ExamType> = yield call(
      ExamService.getExam,
      examId
    );
    const { data } = response;

    yield put(setExamAction(data));

    yield put(setMessageAction(null));

    if (callback) yield call(callback);
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(setMessageAction({ type: 'error', content: error.message }));
    }
  }
  yield put(setIsLoadingAction(false));
}

function* sendExamAnswersSaga(action: SendExamAnswersActionPayloadType) {
  const { answers, callback } = action.payload;

  yield put(setIsLoadingAction(true));
  try {
    const response: AxiosResponse<CandidateExamResultType> = yield call(
      ExamService.sendExamAnswers,
      answers
    );
    const { data } = response;

    yield put(setExamResult(data));

    yield put(setMessageAction(null));

    if (callback) yield call(callback);
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(setMessageAction({ type: 'error', content: error.message }));
    }
  }
  yield put(setIsLoadingAction(false));
}

const examWatcher = [
  takeEvery(ExamSagaActionsType.GET_EXAM_INFO, getExamInfoSaga),
  takeEvery(ExamSagaActionsType.GET_EXAM, getExamSaga),
  takeEvery(ExamSagaActionsType.SEND_EXAM_ANSWERS, sendExamAnswersSaga)
];
export default examWatcher;
