import { Fragment, Suspense, useContext } from 'react';
import { NavContext } from 'contexts/NavContext';
import { Routes, Route, RouteObject, Navigate } from 'react-router-dom';
import Exam from 'pages/Exam';
import ExamResult from 'pages/ExamResult';
import Navbar from 'components/organisms/Navbar';
import ScreenLoading from 'components/organisms/ScreenLoading';
import CandidateGuard from './CandidateGuard';

function Router() {
  const { show } = useContext(NavContext);

  interface RoutesProps extends RouteObject {
    path: string;
    hasNavbar?: boolean;
    element: JSX.Element;
    routes?: RoutesProps[];
  }

  const renderRoutes = (routes: RoutesProps[]): JSX.Element[] =>
    routes.map((route) => {
      const { path, element, routes: childrens, hasNavbar } = route;

      const Nav = hasNavbar && show ? Navbar : Fragment;

      return (
        <Route
          key={path}
          path={path}
          element={
            <CandidateGuard>
              <>
                <Nav />
                {element}
              </>
            </CandidateGuard>
          }
        >
          {childrens && renderRoutes(childrens)}
        </Route>
      );
    });

  const pageNotFound = process.env.REACT_APP_PUBLIC_URL?.includes('localhost')
    ? []
    : [
        {
          path: '*',
          element: <Navigate to="/candidate/exam/404" />
        }
      ];

  const routesList: RoutesProps[] = [
    ...pageNotFound,
    {
      path: '/candidate/exam/:examId',
      element: <Exam />,
      hasNavbar: true
    },
    {
      path: '/candidate/exam/result',
      element: <ExamResult />,
      hasNavbar: true
    }
  ];

  return (
    <Suspense fallback={<ScreenLoading />}>
      <Routes>{renderRoutes(routesList)}</Routes>
    </Suspense>
  );
}

export default Router;
