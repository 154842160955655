import { useEffect, useContext } from 'react';
import { NavContext } from 'contexts/NavContext';
import InstructionsSlider from 'components/molecules/InstructionsSlider';
import useAppDispatch from 'hooks/useAppDispatch';
import { getExamInfoAction } from 'store/modules/exam/actions';
import useAppSelector from 'hooks/useAppSelector';
import InstructionsSkeleton from './InstructionsSkeleton';

interface InstrucionsProps {
  examId: string;
  setStartExam: () => void;
}

function Instructions({ examId, setStartExam }: InstrucionsProps) {
  const dispatch = useAppDispatch();
  const { title, timeQuestions, questionsNumber, attempts } = useAppSelector(
    (state) => state.exam
  );
  const { triggerGA } = useContext(NavContext);

  useEffect(() => {
    if (examId) dispatch(getExamInfoAction(examId));
  }, []);

  useEffect(() => {
    if (title) {
      triggerGA();
    }
  }, [title]);

  return title ? (
    <InstructionsSlider
      examTitle={title ?? ''}
      timePerQuestionInSeconds={timeQuestions ?? 0}
      questionsNumber={questionsNumber}
      attempts={attempts}
      setStartExam={setStartExam}
    />
  ) : (
    <InstructionsSkeleton />
  );
}

export default Instructions;
