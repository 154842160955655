import {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useMemo
} from 'react';
import useAppSelector from 'hooks/useAppSelector';

interface NavContextType {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  triggerGA: (eventData?: object) => void;
}

interface NavProviderProps {
  children: JSX.Element;
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const NavContext = createContext<NavContextType>({
  show: true,
  setShow: () => {},
  triggerGA: () => {}
});

export function NavProvider({ children }: NavProviderProps) {
  const { user, userType } = useAppSelector((state) => state.auth);
  const [show, setShow] = useState(true);

  const triggerGA = (eventData?: object) => {
    if (user) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        ...eventData,
        user_id: user?.id,
        logged: true,
        user_type: userType
      });
    }
  };

  const contextValue = useMemo(() => {
    return {
      show,
      setShow,
      triggerGA
    };
  }, [show, user]);

  return (
    <NavContext.Provider value={contextValue}>{children}</NavContext.Provider>
  );
}
