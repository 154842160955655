import { Box, Skeleton, useMediaQuery } from '@mui/material';
import theme from 'theme';
import { useStyles } from './styles';

function InstructionsSkeleton() {
  const styles = useStyles();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  return (
    <Box className={styles.container}>
      <Skeleton variant="rounded" height={isSmallScreen ? 200 : 300} />
      <Skeleton
        variant="rounded"
        height={isSmallScreen ? 120 : 80}
        style={{ marginTop: 20 }}
      />
      <Skeleton
        variant="rounded"
        height={30}
        width={200}
        style={{ margin: '45px auto 0 auto' }}
      />
      <Skeleton
        variant="rounded"
        height={60}
        style={{ marginTop: isSmallScreen ? 'auto' : 40 }}
      />
    </Box>
  );
}

export default InstructionsSkeleton;
