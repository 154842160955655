import { makeStyles } from '@mui/styles';
import { CustomTheme } from 'types/theme';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: theme.spacing(3)
  }
}));
