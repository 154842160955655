import { makeStyles } from '@mui/styles';
import { CustomTheme } from 'types/theme';

const containerBase = {
  padding: 16,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 12
};

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    ...containerBase
  },
  containerActive: {
    ...containerBase,
    '&.MuiCard-root': {
      borderColor: theme.palette.primary.main,
      background: '#F0F1F9'
    }
  },
  containerDisabled: {
    ...containerBase,
    '&.MuiCard-root': {
      background: '#FAFAFA'
    }
  },
  radio: {
    '&.MuiRadio-root': {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  }
}));
