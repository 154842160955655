import { authSlice } from '.';
import {
  AuthSagaActionsType,
  GetUserActionType,
  LoginActionType
} from './types';

export const loginAction: LoginActionType = (
  email,
  password,
  isCompanyLogin,
  callback
) => {
  return {
    type: AuthSagaActionsType.LOGIN,
    payload: { email, password, isCompanyLogin, callback }
  };
};

export const getUserAction: GetUserActionType = (token, callback) => {
  return {
    type: AuthSagaActionsType.GET_USER,
    payload: { token, callback }
  };
};

export const { setUserAction, setIsLoadingAuthAction } = authSlice.actions;
