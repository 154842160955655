import { Box, CircularProgress } from '@mui/material';
import { useStyles } from './styles';

function ScreenLoading() {
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <CircularProgress />
    </Box>
  );
}

export default ScreenLoading;
