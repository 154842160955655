import { makeStyles } from '@mui/styles';
import { CustomTheme } from 'types/theme';

const iconBase = {
  color: '#FFFFFF',
  borderRadius: 100,
  height: 94,
  width: 94,
  padding: 15
};

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    minHeight: 'calc(100% - 68px)',
    maxWidth: 800,
    margin: '0 auto',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '72px 16px 16px 16px'
    }
  },
  iconApproved: {
    '&.MuiSvgIcon-root': {
      ...iconBase,
      background: '#40BB45'
    }
  },
  iconDisapproved: {
    '&.MuiSvgIcon-root': {
      ...iconBase,
      background: '#BDBDBD'
    }
  },
  description: {
    '&.MuiTypography-root': {
      color: '#28282A'
    }
  },
  button: {
    '&.MuiButton-root': {
      padding: '12px 20px',
      marginTop: 32,
      [theme.breakpoints.down('sm')]: {
        marginTop: 'auto',
        width: '100%'
      }
    }
  }
}));
