import { makeStyles } from '@mui/styles';
import { CustomTheme } from 'types/theme';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 800,
    margin: '0 auto',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 16
    }
  }
}));
