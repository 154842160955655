import { Skeleton, useMediaQuery } from '@mui/material';
import theme from 'theme';

function QuestionsSkeleton() {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  return (
    <>
      <Skeleton
        variant="rounded"
        height={isSmallScreen ? 140 : 80}
        style={{ marginTop: 70, marginBottom: 16 }}
      />
      <Skeleton variant="rounded" height={300} style={{ marginBottom: 16 }} />
      <Skeleton
        variant="rounded"
        height={60}
        style={{ marginTop: isSmallScreen ? 'auto' : 0 }}
      />
    </>
  );
}

export default QuestionsSkeleton;
