import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  currentPosition: {
    '&.MuiTypography-root': {
      fontSize: 11,
      fontWeight: 700,
      color: '#00000061',
      letterSpacing: 1,
      marginTop: 16
    }
  },
  description: {
    overflowWrap: 'break-word'
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    margin: '16px 0 22px 0'
  },
  nextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0',
    position: 'fixed',
    bottom: 0
  },
  timeContainer: {
    background: '#F6F6F6',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    border: '1px solid #EDEDED'
  },
  time: {
    color: '#00000099'
  },
  disabledButton: {
    '&.MuiIconButton-root': {
      background: '#E0E0E0',
      color: '#00000042'
    }
  }
}));