import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { urlCandidateArea } from 'utils/redirect';
import { Box, Typography, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import useAppSelector from 'hooks/useAppSelector';
import { formattedDate } from 'utils/formatters';

import { useStyles } from './styles';

function ExamResult() {
  const styles = useStyles();

  const [searchParams] = useSearchParams();
  const { result, title } = useAppSelector((state) => state.exam);

  const validResult = ['APPROVED', 'UNAPPROVED'].includes(
    result?.situation ?? ''
  );

  const goToCandidateArea = () => {
    const url = urlCandidateArea(searchParams.get('applicationId') ?? '');

    window.location.href = url;
  };

  useEffect(() => {
    if (!validResult) {
      goToCandidateArea();
    }
  }, []);

  const marginTitle = { marginTop: 16 };
  const marginDescription = { marginTop: 8 };

  const showApproved = () => (
    <>
      <CheckIcon className={styles.iconApproved} />
      <Typography variant="h4" style={marginTitle}>
        Parabéns, você se certificou!
      </Typography>
      <Typography style={marginDescription}>
        Testamos seus conhecimentos em <strong>{title}</strong> e você acertou{' '}
        <strong>
          {result?.quantityCorrect} de {result?.quantity} questões
        </strong>
        .
      </Typography>
    </>
  );

  const showDisapproved = () => (
    <>
      <CloseIcon className={styles.iconDisapproved} />
      <Typography variant="h4" style={marginTitle}>
        Infelizmente, você não se certificou.
      </Typography>
      <Typography className={styles.description} style={marginDescription}>
        Testamos seus conhecimentos em <strong>{title}</strong> e você acertou{' '}
        <strong>
          {result?.quantityCorrect} de {result?.quantity} questões
        </strong>
        .
      </Typography>
      <Typography className={styles.description}>
        {result?.restartAt && result?.attempts === 2 ? (
          <>
            Você pode realizar a certificação novamente a partir de{' '}
            <strong>{formattedDate(result?.restartAt ?? '')}</strong>.
          </>
        ) : (
          <>
            Você ainda possui mais uma tentativa para realizar a certificação.
          </>
        )}
      </Typography>
    </>
  );

  const showMessage = () =>
    result?.situation === 'APPROVED' ? showApproved() : showDisapproved();

  return (
    <Box className={styles.container}>
      {validResult && (
        <>
          {showMessage()}
          <Button
            onClick={goToCandidateArea}
            className={styles.button}
            size="large"
          >
            voltar para área do candidato
          </Button>
        </>
      )}
    </Box>
  );
}

export default ExamResult;
