
import format from 'date-fns/format';

export const formattedDate = (date: string) => (
	format(new Date(date), 'dd/MM/yyyy')
);

export const formattedExamTitle = (title: string, level: number) => {
	let newLevel = 'Básico';

	if (level === 2) {
		newLevel = 'Intermediário';
	} else if (level === 3) {
		newLevel = 'Avançado';
	}

	return `${title} - ${newLevel}`;
};