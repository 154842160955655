import { examSlice } from '.';
import {
  ExamSagaActionsType,
  GetExamActionType,
  SendExamAnswersActionType
} from './types';

export const getExamInfoAction: GetExamActionType = (examId, callback) => {
  return {
    type: ExamSagaActionsType.GET_EXAM_INFO,
    payload: { examId, callback }
  };
};

export const getExamAction: GetExamActionType = (examId, callback) => {
  return {
    type: ExamSagaActionsType.GET_EXAM,
    payload: { examId, callback }
  };
};

export const sendExamAnswersAction: SendExamAnswersActionType = (
  answers,
  callback
) => {
  return {
    type: ExamSagaActionsType.SEND_EXAM_ANSWERS,
    payload: { answers, callback }
  };
};

export const { setExamInfoAction, setExamAction, setExamResult } = examSlice.actions;
