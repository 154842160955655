import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { NavProvider } from 'contexts/NavContext';

import AppProvider from 'components/AppProvider';
import GoogleTagManager from 'googleTools/GoogleTagManager';
import store from 'store';
import theme from 'theme';
import Router from './router';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <NavProvider>
          <BrowserRouter>
            <AppProvider>
              <>
                <Router />
                <GoogleTagManager />
              </>
            </AppProvider>
          </BrowserRouter>
        </NavProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
