import { makeStyles } from '@mui/styles';
import { CustomTheme } from 'types/theme';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20px',
    textAlign: 'center',
    minHeight: 'calc(100% - 68px)',
    paddingBottom: '0',
    minWidth: '360px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center'
    }
  },
  carrouselContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '445px',
    [theme.breakpoints.down('xl')]: {
      maxHeight: '320px'
    }
  },
  title1: {
    marginTop: '7px !important',
    fontSize: '34px !important',
    fontWeight: '400px',
    lineHeight: '42px !important',
    letterSpacing: '0.25 !important',
    maxWidth: '100%',
    left: '16px',
    top: '16px',
    borderRadius: 'nullpx',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginTop: '40px !important',
      padding: '0px 16px'
    }
  },
  instructions: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: '20px !important',
      paddingRight: '20px !important',
      marginBottom: '0px'
    }
  },
  mobileSteps: {
    display: 'flex !important',
    flexDirection: 'column',
    borderRadius: '0px',
    marginTop: '15px',
    padding: '8px, 0px, 0px, 0px',
    '&.MuiMobileStepper-root': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '46px'
    }
  },
  mobileStepsButtons: {
    width: '450px',
    marginTop: '50px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&.MuiPaper-root': {
      position: 'initial'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'spaceBetween',
      width: '100%',
      paddingLeft: '20px !important',
      paddingRight: '20px !important',
      paddingBottom: '16px !important',
      marginTop: 'auto',
      '&.MuiPaper-root': {
        position: 'unset'
      }
    }
  },
  backButton: {
    '&.MuiButtonBase-root': {
      color: '#646464',
      backgroundColor: 'transparent'
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'transparent'
    }
  }
}));
