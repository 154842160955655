import { IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useStyles } from './styles';

interface NextButtonProps {
  onClick: () => void;
  text?: string;
  disabled?: boolean;
  loading?: boolean;
  id?: string;
}

function NextButton({ onClick, text, disabled, loading, id }: NextButtonProps) {
  const styles = useStyles();

  const extendedButton = () => (
    <LoadingButton
      id={id}
      className={styles.extendedButton}
      size="large"
      onClick={onClick}
      disabled={disabled}
      startIcon={<ArrowForwardIcon />}
      loading={loading}
      loadingPosition="start"
      variant="contained"
    >
      {text}
    </LoadingButton>
  );

  const iconButton = () => (
    <IconButton
      id={id}
      className={styles.iconButton}
      onClick={onClick}
      disabled={disabled}
    >
      <ArrowForwardIcon />
    </IconButton>
  );

  return text ? extendedButton() : iconButton();
}

export default NextButton;
