import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { urlCandidateArea } from 'utils/redirect';
import useAppSelector from 'hooks/useAppSelector';
import Instructions from './Instructions';
import Questions from './Questions';

function Exam() {
  const { examId } = useParams<{ examId: string }>();
  const [searchParams] = useSearchParams();
  const { message } = useAppSelector((state) => state.helper);
  const { attempts } = useAppSelector((state) => state.exam);

  const [startExam, setStartExam] = useState(false);

  const goToCandidateArea = () => {
    const url = urlCandidateArea(searchParams.get('applicationId') ?? '');
    window.location.href = url;
  };

  useEffect(() => {
    if (message || attempts > 2) {
      goToCandidateArea();
    }
  }, [message, attempts]);

  return startExam ? (
    <Questions examId={examId ?? ''} />
  ) : (
    <Instructions
      examId={examId ?? ''}
      setStartExam={() => setStartExam(true)}
    />
  );
}

export default Exam;
