import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import authReducer from './modules/auth';
import helperReducer from './modules/helper';
import examReducer from './modules/exam';
import rootSaga from './rootSaga';

const saga = createSagaMiddleware();
const store = configureStore({
  reducer: {
    auth: authReducer,
    helper: helperReducer,
    exam: examReducer
  },
  middleware: [saga]
});
saga.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
