import { PayloadAction } from '@reduxjs/toolkit';
import { UserType } from 'types/user';

export interface AuthStateType {
  token: string | null;
  user: UserType | null;
  userType: 'company' | 'candidate' | null;
  isAuthenticated: boolean;
  isLoadingAuth: boolean;
}

export enum AuthSagaActionsType {
  LOGIN = 'LOGIN',
  GET_USER = 'GET_USER'
}

export type LoginActionPayloadType = PayloadAction<{
  email: string;
  password: string;
  isCompanyLogin: boolean;
  callback?: () => void;
}>;

export type LoginActionType = (
  email: string,
  password: string,
  isCompanyLogin: boolean,
  callback?: () => void
) => LoginActionPayloadType;

export type GetUserActionPayloadType = PayloadAction<{
  token: string;
  callback?: () => void;
}>;

export type GetUserActionType = (
  token: string,
  callback?: () => void
) => GetUserActionPayloadType;
