import AppBar from '@mui/material/AppBar';
import { useSearchParams } from 'react-router-dom';
import { urlCandidateArea } from 'utils/redirect';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as Logo } from 'assets/logos/topbar-logo.svg';
import { useStyles } from './styles';

function NavBar() {
  const styles = useStyles();
  const [searchParams] = useSearchParams();

  const goToCandidateArea = () => {
    const url = urlCandidateArea(searchParams.get('applicationId') ?? '');

    window.location.href = url;
  };

  return (
    <AppBar position="static" style={{ boxShadow: 'none' }}>
      <Box className={styles.container}>
        <Logo className={styles.logo} />
        <CloseIcon className={styles.closeIcon} onClick={goToCandidateArea} />
      </Box>
    </AppBar>
  );
}
export default NavBar;
