/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useState, useEffect, useContext } from 'react';
import { NavContext } from 'contexts/NavContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CandidateAnswerType } from 'types/exam';
import { Box } from '@mui/material';
import useAppSelector from 'hooks/useAppSelector';
import Question from 'components/organisms/Question';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  getExamAction,
  sendExamAnswersAction
} from 'store/modules/exam/actions';
import QuestionsSkeleton from './QuestionsSkeleton';

import { useStyles } from './styles';

interface ExamProps {
  examId: string;
}

function Exam({ examId }: ExamProps) {
  const styles = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { setShow } = useContext(NavContext);
  const { questions, id } = useAppSelector((state) => state.exam);
  const { isLoading } = useAppSelector((state) => state.helper);

  const [index, setIndex] = useState(0);
  const [candidateAnswers, setCandidateAnswers] = useState<
    CandidateAnswerType[]
  >([]);
  const [selectedAnswerId, setSelectedAnswerId] = useState('');

  const onBeforeUnload = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    setShow(false);
    dispatch(getExamAction(examId));
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      setShow(true);
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  const currentQuestion = questions[index];
  const currentPosition = index + 1;
  const totalQuestions = questions.length;

  const situation = {
    currentPosition,
    totalQuestions
  };

  const markAnswer = (answerId: string) => {
    setSelectedAnswerId(answerId);
  };

  const applicationId = searchParams.get('applicationId');
  const COMPLEMENT = applicationId ? `?applicationId=${applicationId}` : '';

  const submit = () => {
    dispatch(
      sendExamAnswersAction(
        {
          examId: id || '',
          answers: candidateAnswers.filter((item) => item.answerId)
        },
        () => navigate(`/candidate/exam/result${COMPLEMENT}`, { replace: true })
      )
    );
  };

  useEffect(() => {
    if (candidateAnswers.length) submit();
  }, [candidateAnswers.length === questions.length]);

  const next = (questionTime: number) => {
    const newCandidateAnswer: CandidateAnswerType = {
      answerId: selectedAnswerId,
      questionId: currentQuestion.id,
      questionTime: questionTime * 1000 // CONVERTING TO MILLISECONDS
    };

    setCandidateAnswers((prev) => [...prev, newCandidateAnswer]);
    if (currentPosition < totalQuestions) {
      setSelectedAnswerId('');
      setIndex((state) => state + 1);
    }
  };

  return (
    <Box className={styles.container}>
      {currentQuestion ? (
        <Question
          question={currentQuestion}
          situation={situation}
          selectedAnswerId={selectedAnswerId}
          markAnswer={markAnswer}
          next={(time: number) => next(time)}
          isLoading={isLoading}
        />
      ) : (
        <QuestionsSkeleton />
      )}
    </Box>
  );
}

export default Exam;
