import { Card, Typography, Radio } from '@mui/material';
import parse from 'html-react-parser';
import theme from 'theme';
import { useStyles } from './styles';

interface AlternativeCardProps {
  title: string;
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
}

function AlternativeCard({
  title,
  selected,
  onClick,
  disabled
}: AlternativeCardProps) {
  const styles = useStyles();

  const stylesContainer = () => {
    let styleCard = styles.container;

    if (selected) {
      styleCard = styles.containerActive;
    } else if (disabled) {
      styleCard = styles.containerDisabled;
    }

    return styleCard;
  };

  const cardCursor = disabled ? 'default' : 'pointer';
  const textColor = disabled && !selected ? '#00000099' : '';
  const radioColor = selected ? theme.palette.primary.main : '';

  const onClickAction = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <Card
      onClick={onClickAction}
      className={stylesContainer()}
      style={{ cursor: cardCursor }}
    >
      <Typography variant="body2" style={{ color: textColor }}>
        {parse(title)}
      </Typography>
      <Radio
        checked={selected}
        className={styles.radio}
        style={{ color: radioColor }}
        disabled={disabled}
      />
    </Card>
  );
}

export default AlternativeCard;
