import { useSearchParams } from 'react-router-dom';
import { urlCandidateArea } from 'utils/redirect';
import { useTheme } from '@mui/material/styles';
import { Box, MobileStepper, Typography, Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useState } from 'react';
import { ReactComponent as Ilustration1 } from 'assets/Instructions/ilustraInstruction1.svg';
import { ReactComponent as Ilustration2 } from 'assets/Instructions/ilustraInstruction2.svg';
import { ReactComponent as Ilustration3 } from 'assets/Instructions/ilustraInstruction3.svg';
import { ReactComponent as Ilustration4 } from 'assets/Instructions/ilustraInstruction4.svg';
import { ReactComponent as Ilustration5 } from 'assets/Instructions/ilustraInstruction5.svg';
import { ReactComponent as Ilustration6 } from 'assets/Instructions/ilustraInstruction6.svg';

import NextButton from 'components/atoms/NextButton';
import { useStyles } from './styles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const getSlides = (
  examTitle: string,
  timePerQuestionInSeconds: number,
  questionsNumber: number,
  attempts: number
) => {
  const minutes = Math.floor(timePerQuestionInSeconds / 60);
  const seconds = timePerQuestionInSeconds % 60;
  const formattedTime = `${minutes}:${String(seconds).padStart(2, '0')}`;
  const timeUnit = () => {
    let unit = 'segundos';

    if (minutes > 1 || (minutes > 0 && seconds > 0)) {
      unit = 'minutos';
    } else if (minutes === 1) {
      unit = 'minuto';
    }

    return unit;
  };

  return [
    {
      id: 1,
      title: 'Certificação de habilidades',
      instructions: `Você está prestes a comprovar suas habilidades em ${examTitle}.`
    },
    {
      id: 2,
      title: 'Como funciona?',
      instructions: `A avaliação possui ${questionsNumber} perguntas múltipla escolha e você terá ${formattedTime} ${timeUnit()} por questão.`
    },
    {
      id: 3,
      title: `Você terá ${formattedTime} ${timeUnit()} por questão`,
      instructions:
        'Ao esgotar o tempo, você avançará automaticamente para a próxima.'
    },
    {
      id: 4,
      title: 'Tenha muita atenção a cada resposta',
      instructions: 'Você não poderá voltar e trocar a resposta de uma questão.'
    },
    {
      id: 5,
      title: 'Confira o sinal da internet',
      instructions: 'Você precisará concluir a avaliação em uma única sessão'
    },
    {
      id: 6,
      title: 'Você terá duas tentativas para se certificar',
      instructions: (
        <>
          A tentativa será perdida caso você <strong>feche</strong>,{' '}
          <strong>atualize</strong> ou clique em <strong>voltar</strong> no seu{' '}
          <strong>navegador</strong>. Para confirmar o início da tentativa{' '}
          <strong>{attempts} de 2</strong>, clique em <strong>começar</strong>.
        </>
      )
    }
  ];
};

interface SvgChangeProps {
  id: number;
  className?: string;
}

function SvgChange({ id, className }: SvgChangeProps) {
  switch (id) {
    case 1:
      return <Ilustration1 className={className} />;
    case 2:
      return <Ilustration2 className={className} />;
    case 3:
      return <Ilustration3 className={className} />;
    case 4:
      return <Ilustration4 className={className} />;
    case 5:
      return <Ilustration5 className={className} />;
    default:
      return <Ilustration6 className={className} />;
  }
}

interface InstructionsSliderProps {
  examTitle: string;
  timePerQuestionInSeconds: number;
  questionsNumber: number;
  attempts: number;
  setStartExam: () => void;
}

function InstructionsSlider({
  examTitle,
  timePerQuestionInSeconds,
  questionsNumber,
  attempts,
  setStartExam
}: InstructionsSliderProps) {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const [activeStep, setActiveStep] = useState(0);
  const slides = getSlides(
    examTitle,
    timePerQuestionInSeconds,
    questionsNumber,
    attempts
  );
  const maxSteps = slides.length;
  const styles = useStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const goToCandidateArea = () => {
    const url = urlCandidateArea(searchParams.get('applicationId') ?? '');
    window.location.href = url;
  };

  return (
    <Box className={styles.content}>
      <AutoPlaySwipeableViews
        slideStyle={{ overflow: 'hidden !important', width: '100%' }}
        autoplay={false}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {slides.map((step, index) => (
          <div key={step.title}>
            <Box className={styles.carrouselContent}>
              {Math.abs(activeStep - index) <= 2 ? (
                <>
                  <SvgChange id={step.id} />
                  <Typography
                    id="text1-candidate-instruction-text-id"
                    className={styles.title1}
                  >
                    {step.title}
                  </Typography>
                  <Typography
                    id="text2-candidate-instruction-text-id"
                    className={styles.instructions}
                    style={{ marginTop: '15px' }}
                  >
                    {step.instructions}
                  </Typography>
                </>
              ) : null}
            </Box>
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        className={styles.mobileSteps}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        backButton={undefined}
        nextButton={undefined}
      />
      <MobileStepper
        className={styles.mobileStepsButtons}
        nextButton={
          activeStep !== 5 ? (
            <NextButton
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              id="next-candidate-instruction-button-id"
            />
          ) : (
            <NextButton
              id="next-candidate-instruction-button-id"
              onClick={setStartExam}
              text="COMEÇAR"
            />
          )
        }
        backButton={
          activeStep === 0 ? (
            <Button
              id="back-candidate-instruction-button-id"
              style={{ backgroundColor: 'transparent' }}
              onClick={goToCandidateArea}
              variant="text"
            >
              QUERO FAZER DEPOIS
            </Button>
          ) : (
            <Button
              id="back-candidate-instruction-button-id"
              className={styles.backButton}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <ArrowBack />
            </Button>
          )
        }
        steps={0}
      />
    </Box>
  );
}

export default InstructionsSlider;
