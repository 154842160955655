import { makeStyles } from '@mui/styles';
import { CustomTheme } from 'types/theme';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% - 68px)',
    maxWidth: 550,
    margin: '0 auto',
    padding: '40px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: 16
    }
  }
}));
