import { makeStyles } from '@mui/styles';
import { CustomTheme } from 'types/theme';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  iconButton: {
    '&.MuiIconButton-root': {
      background: theme.palette.primary.main,
      color: '#FFFFFF',
      '&.Mui-disabled': {
        background: '#E0E0E0 !important',
        color: '#00000042'
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    }
  },
  extendedButton: {
    '&.MuiLoadingButton-root': {
      background: theme.palette.primary.main,
      color: '#FFFFFF',
      borderRadius: 24,
      padding: '12px 16px',
      letterSpacing: 0.46,
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    },
    display: 'flex',
    gap: 8
  }
}));
