import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    padding: 18
  },
  closeIcon: {
    position: 'absolute',
    right: 18,
    fontSize: 24,
    color: '#0000008A',
    cursor: 'pointer'
  },
  logo: {
    width: 59.65,
    height: 22.57
  }
}));
