import useAppSelector from 'hooks/useAppSelector';

interface CandidateRouteProps {
  children: JSX.Element;
}

function CandidateRoute({ children }: CandidateRouteProps) {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  if (!isAuthenticated) {
    window.location.href = `${process.env.REACT_APP_GRIA_URL}/candidate/login`;
    return null;
  }

  return children;
}

export default CandidateRoute;
