import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserType } from 'types/user';
import { AuthStateType } from './types';

const INITIAL_STATE: AuthStateType = {
  token: null,
  user: null,
  userType: null,
  isAuthenticated: false,
  isLoadingAuth: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setUserAction: (
      state,
      action: PayloadAction<{
        token: string;
        userType: 'candidate' | 'company';
        user: UserType;
      }>
    ) => {
      const { user, token, userType } = action.payload;

      state.user = user;
      state.token = token;
      state.userType = userType;
      state.isAuthenticated = true;
    },
    setIsLoadingAuthAction: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAuth = action.payload;
    },
  }
});

export default authSlice.reducer;
